export const locale = {
  lang: 'zh',
  data: {
    SIDE_NAV_HOLD_MFS_WALLET: {
      BACK: '後退',
      HOLD_MFS:'保持MFS',
      HOLD_HMFS:'保持hMFS',
      HOLD: '抓住',
      DAY: '天',
      GET: '得到',
      INNER_BALANCE: '內在平衡',
      HMFS_TO_MFS: 'hMFS 至 MFS',
      TAKE_MFS:'採取MFS',
      TAKE_HMFS:'採取hMFS',
    },
  },
};
