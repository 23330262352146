export const locale = {
  lang: 'en',
  data: {
    SIDE_NAV_HOLD_MFS: {
      BACK: 'Back',
      MAX: 'Max',
      HOLD: 'Hold',
      BALANCE: 'BALANCE:',
      GET:"Get",
      AVAILABLE:"Available",
      YOU_GET:"You are getting"
    },
  },
};
