export const locale = {
  lang: 'zh',
  data: {
    WALLET: {
      WALLET: '钱包',
      BALANCE: '余额',
      DISCONNECT: '断开连接',
      CANCEL: '取消',
      REPLENISH: '充值',
      SEND_TO_WALLET: '发送到钱包',
      EXTRA_WALLET: '额外钱包',
      CHECK_NAME: '自动充值钱包',
    },
  },
};
