export const locale = {
  lang: 'hi',
  data: {
    SIDE_NAV_WALLET_ACTION: {
      BACK: 'वापस',
      SELL_MFC: 'MFS बेचें',
      IMPORTANTLY: 'महत्वपूर्ण रूप से',
      INFO: 'यहाँ आप अपने MFS को 2.77 DAI प्रति 1 MFS की दर से बिक्री के लिए सूचीबद्ध कर सकते हैं। आपके MFS को उन उपयोगकर्ताओं को बेचा जाएगा जिनके पास Uniteverse में टियर्स सक्रिय करने के लिए MFS नहीं है। आवेदनों को कतार के क्रम में संसाधित किया जाता है। आंशिक बिक्री की स्थिति में, आवेदन को कतार के अंत में भेज दिया जाता है।',
      YOU_GIVE: 'आप देते हैं',
      AVAILABLE: 'उपलब्ध',
      MAX: 'अधिकतम',
      YOU_RECEIVE: 'आप प्राप्त करेंगे',
      SELL: 'बेचें',
      OPEN: 'खोलें',
      QUEUE: 'आपकी बारी के माध्यम से',
      OPERATIONS: 'ऑपरेशन',
      CANCEL_ORDER: 'ऑर्डर रद्द करें',
      COMPLETED: 'पूरा कर दिया गया',
      AMOUNT: 'कुल राशि',
      BUYBACK_INFO: "क्या आप बिना कतार में लगे तुरंत बेचना चाहते हैं? अधिक जानकारी के लिए",
      BUYBACK_LINK: "यहाँ क्लिक करें",
    },
  },
};
