export const locale = {
  lang: 'hi',
  data: {
    SIDE_NAV_HOLD_MFS_WALLET: {
      BACK: 'वापस',
      HOLD_MFS:'पकड़ो MFS',
      HOLD_HMFS:'पकड़ो hMFS',
      HOLD: 'पकड़ो',
      DAY: 'दिन',
      GET: 'प्राप्त करें',
      INNER_BALANCE: 'आंतरिक संतुलन',
      HMFS_TO_MFS: 'hMFS से MFS',
      TAKE_MFS:'ले लो MFS',
      TAKE_HMFS:'ले लो hMFS',
    },
  },
};
