export const locale = {
  lang: 'id',
  data: {
    RECENT_OPERATIONS: {
      RECENT_TRANSACTIONS: 'Transaksi terbaru',
      AMOUNT: 'Jumlah',
      NO_OPERATIONS: 'Tidak ada operasi',
      COPY_LINK: 'Salin tautan',
      TX_HASH: 'Hash transaksi',
    },
  },
};
