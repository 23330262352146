export const locale = {
  lang: 'ar',
  data: {
    WALLET: {
      WALLET: 'المحفظة',
      BALANCE: 'الرصيد الداخلي',
      DISCONECT: 'قطع الاتصال',
      CANCEL: 'إلغاء',
      REPLENISH: 'إعادة التعبئة',
      SEND_TO_WALLET: 'إرسال إلى المحفظة',
      CHECK_NAME: 'إعادة التعبئة التلقائية للمحفظة',
      EXTRA_WALLET: 'محفظة إضافية',
    },
  },
};
