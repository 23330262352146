export const locale = {
  lang: 'ar',
  data: {
    SIDE_NAV_HOLD_MFS_WALLET: {
      BACK: 'خلف',
      HOLD_MFS:'عقد MFS',
      HOLD_HMFS:'عقد hMFS',
      HOLD: 'يمسك',
      DAY: 'يوم',
      GET: 'يحصل',
      INNER_BALANCE: 'التوازن الداخلي',
      HMFS_TO_MFS: 'MFS إلى hMFS',
      TAKE_MFS:'خذ MFS',
      TAKE_HMFS:'خذ hMFS',
    },
  },
};
