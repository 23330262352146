export const locale = {
  lang: 'en',
  data: {
    LAYOUT: {
      BACK: 'Back',
      TITLE: 'Wallet',
      WALLET_LIST: 'The list of available wallets',
      CLOSE: 'Close',
    },
  },
};
