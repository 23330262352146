<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'energy'"
  src="/assets/coinIcon/energy.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'dai'"
  src="/assets/coinIcon/dai.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'forcecoin'"
  src="/assets/coinIcon/mfs.png"
  alt="" />
  <img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'hmfs'"
  src="/assets/coinIcon/hmfs.svg"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'sfcr'"
  src="/assets/coinIcon/sfcr.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'sfcr2'"
  src="/assets/coinIcon/sfcr2.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'sfc2'"
  src="/assets/coinIcon/sfc2.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'sfcnew'"
  src="/assets/coinIcon/sfcNew.png"
  alt="" />

<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'sfcold'"
  src="/assets/coinIcon/sfcOld.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'matic'"
  src="/assets/coinIcon/matic.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'rt'"
  src="/assets/coinIcon/rt.png"
  alt="" />
