export const locale = {
  lang: 'zh',
  data: {
    SIDE_NAV_NOTIFICATION: {
      NOTIFICATION: '通知',
      MARK_AS_READ: '标记为已读',
      NEW_NOTIFICATION_NOT_FOUND: '未找到新通知...',
    },
  },
};
