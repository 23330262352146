export const locale = {
  lang: 'ar',
  data: {
    RECENT_OPERATIONS: {
      RECENT_TRANSACTIONS: 'المعاملات الأخيرة',
      AMOUNT: 'المبلغ',
      NO_OPERATIONS: 'لا عمليات',
      COPY_LINK: 'نسخ الرابط',
      TX_HASH: 'هاش المعاملة',
    },
  },
};
