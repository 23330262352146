export const locale = {
  lang: 'id',
  data: {
    SIDE_NAV_WALLET_VESTING: {
      BACK: 'Kembali',
      CONING_SOON: 'Segera Hadir',
      INNER_BALANCE: 'Saldo Dalam',
      VESTING_BALANCE: 'Saldo Vesting',
      OPEN: 'Buka',
      OPERATIONS: 'Operasi',
      RELEASED: 'Telah Dirilis',
      AMOUNT: 'Jumlah total',
      YOUR_TARIFF: "Tarif Anda:",
      TARIFF_5_NAME: 'Tarif Vesting 2 Bulan',
      TARIFF_1_NAME: 'Tarif Penahanan 1 Tahun',
      TARIFF_2_NAME: 'Tarif Penahanan 2 Tahun',
      TARIFF_0_NAME: 'Tarif Penahanan 3 Tahun',
      TARIFF_3_NAME: 'Tarif Penahanan 4 Tahun',
      TARIFF_4_NAME: 'Tarif Penahanan 5 Tahun',
      YOU_CAN_SELL_VESTING_MFS: 'Anda dapat menjual Forcecoin dari saldo vesting Anda tanpa menunggu pencairan dengan mengikuti',
      THIS_LINK: 'tautan ini',
    },
  },
};
