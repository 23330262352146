import { Component, OnInit } from '@angular/core';
import { locale as russian } from './i18n/ru';
import { locale as english } from './i18n/en';
import { locale as chine } from './i18n/zh';
import { locale as hindi } from './i18n/hi';
import { locale as vietnam } from './i18n/vi';
import { locale as arab } from './i18n/id';
import { locale as indonesia } from './i18n/ar';
import { locale as urdu } from './i18n/ur';
import { locale as french } from './i18n/fr';
import { CoreTranslationService } from '@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { HelpInfo } from 'app/auth/helpers';
import { ProgressBarMode } from '@angular/material/progress-bar';

@Component({
  selector: 'app-expectation-modal',
  templateUrl: './expectation-modal.component.html',
  styleUrls: ['./expectation-modal.component.scss'],
})
export class ExpectationModalComponent implements OnInit {
  step: number = 1;
  progressBarValue = 0;
  interval: any;

  constructor(
    public translate: TranslateService,
    private coreTranslationService: CoreTranslationService,
    private helpInfo: HelpInfo
  ) {
    this.coreTranslationService.translate(
      russian,
      english,
      chine,
      hindi,
      vietnam,
      arab,
      indonesia,
      urdu,
      french
    );
  }

  ngOnInit(): void {
    this.helpInfo.expectainModalStep.subscribe(x => {
      this.step = x;
      if (x === 3) {
        this.progressBarValue = 0;
        this.interval = setInterval(() => {
          this.progressBarValue += 1.5;
          if (this.progressBarValue >= 90) {
            clearInterval(this.interval);
          }
        }, 500);
      }
    });
  }
}
