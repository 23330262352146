export const locale = {
  lang: 'fr',
  data: {
    SIDE_NAV_SWAP_HMFS_ON_MFS: {
      BACK: 'Dos',
      MAX: 'Max',
      SWAP_HMFS_MFS:"Échanger hMFS en MFS",
      SWAP:"Échange",
      BALANCE: 'ÉQUILIBRE:',
    },
  },
};
