export const locale = {
  lang: 'ru',
  data: {
    BREADCRUMB: {
      FORCE_SYSTEMS: 'Force система',
      UNITEVERSE_DELTA: 'Uniteverse Delta',
      FORCECOIN: 'Forcecoin',
      MATRIX: 'Матрица',
      RECENT_ACTIVITY: 'История транзакций',
      LORE: 'Legends of Uniteverse',
      BACK: 'Back',
      OVERVIEW: 'Месторождение',
      TIERS: 'Уровни',
      PROCESSING: 'Волны'
    },
  },
};
