export const locale = {
  lang: 'en',
  data: {
    SIDE_NAV_HOLD_MFS_WALLET: {
      BACK: 'Back',
      HOLD_MFS:'Hold MFS',
      HOLD_HMFS:'Hold hMFS',
      HOLD: 'HOLD',
      DAY: 'day',
      GET: 'GET',
      INNER_BALANCE: 'Inner Balance',
      HMFS_TO_MFS: 'hMFS to MFS',
      TAKE_MFS:'TAKE MFS',
      TAKE_HMFS:'TAKE hMFS', 
    },
  }
};
