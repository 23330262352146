export const locale = {
  lang: 'ur',
  data: {
    WALLET: {
      WALLET: 'Wallet',
      BALANCE: 'Inner Balance',
      DISCONECT: 'Disconect',
      CANCEL: 'Cancel',
      REPLENISH: 'Replenish',
      SEND_TO_WALLET: 'Send to wallet',
      CHECK_NAME: 'Automatic replenishment of the wallet',
      EXTRA_WALLET: 'Extra Wallet',
    },
  },
};
