<!-- vertical-layout -->
<ng-container>
<!--  {{ error }}-->
  <vertical-layout></vertical-layout>
</ng-container>
<!-- / vertical-layout -->

<app-expectation-modal
  class="w-100"
  *ngIf="expectainModalIsOpen"></app-expectation-modal>
