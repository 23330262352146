export const locale = {
  lang: 'vi',
  data: {
    RECENT_OPERATIONS: {
      RECENT_TRANSACTIONS: 'Giao dịch gần đây',
      AMOUNT: 'Số lượng',
      NO_OPERATIONS: 'Không có hoạt động',
      COPY_LINK: 'Sao chép liên kết',
      TX_HASH: 'Transaction hash',
    },
  },
};
