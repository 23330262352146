export const locale = {
  lang: 'ur',
  data: {
    RECENT_OPERATIONS: {
      RECENT_TRANSACTIONS: 'حالیہ لین دین',
      AMOUNT: 'رقم',
      NO_OPERATIONS: 'کوئی آپریشنز نہیں',
      COPY_LINK: 'لنک کاپی کریں',
      TX_HASH: 'ٹرانزیکشن ہیش',
    },
  },
};
