export const locale = {
  lang: 'ru',
  data: {
    EXPECTATION_MODAL: {
      STEP: 'Шаг',
      OUT_OF: ' из 2',
      CONFIRM_ACTION: 'Подтвердите действие',
      CONFIRM_ACTION_BODY:
        'Эта операция в умном контракте предназначена для получения вашего разрешения на управление средствами в текущем контракте. Вы устанавливаете лимит суммы средств, которую вы готовы передать контракту для управления. Пожалуйста, введите сумму, которой вы доверяете умному контракту для управления, в предоставленное поле. Обратите внимание, что эта сумма должна быть больше или равна значению текущего действия. После ввода суммы нажмите "Подтвердить", чтобы продолжить операцию. Спасибо за использование наших услуг!',
      PLEASE_CONFIRM_YOUR_TRANSACTION_WALLET:
        'Пожалуйста, подтвердите вашу транзакцию в кошельке.',
      FINAL_STEP: 'Последний шаг',
      PLEASE_WAIT: 'Пожалуйста, подождите.',
      IT_TAKES_UP_30_SEC: 'Это может занять до 30 секунд',
      PLEASE_WAIT_BODY:
        'Операция выполняется в умном контракте. Пожалуйста, не закрывайте окно и не обновляйте страницу, пока мы обрабатываем транзакцию. Время выполнения операции на стороне блокчейна обычно составляет от 1 до 30 секунд, но при загруженности сети это может занять больше времени. Мы благодарим вас за терпение и будем информировать вас о завершении операции.',
    },
  },
};
