export const locale = {
  lang: 'ru',
  data: {
    SIDE_NAV_WALLET_HISTORY: {
      BACK: 'Back',
      CONING_SOON: 'Coning soon',
      SEND: 'Send',
      DEPOSIT: 'Deposit',
      SWAP: 'Swap',
      TRANSFER: 'Transfer',
      INNER_BALANCE: 'Inner Balance',
      BALANCE: 'Balance',
      TRANSACTION_HISTORY: 'TRANSACTION HISTORY',
      SEND_TO_WALLET: 'Send to wallet',
      WALLET_BALANCE: 'Wallet balance',
      TRANSFER_INNER_BALANCE: 'Transfer to inner balance',
      TRANSFER_WALLET: 'Transfer to wallet',
    },
  },
};
