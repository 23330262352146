export const locale = {
  lang: 'fr',
  data: {
    SIDE_NAV_WALLET_ACTION: {
      BACK: 'Retour',
      SELL_MFC: 'Vendre MFS',
      IMPORTANTLY: 'Important',
      INFO: "Ici, vous pouvez mettre en vente vos MFS au prix de 2.77 DAI pour 1 MFS. Vos MFS seront vendus aux utilisateurs qui n'ont pas de MFS pour activer les niveaux dans Uniteverse. Les demandes sont traitées dans l'ordre de la file d'attente. En cas de vente partielle, la demande est envoyée à la fin de la file.",
      YOU_GIVE: 'VOUS DONNEZ',
      AVAILABLE: 'DISPONIBLE',
      MAX: 'Max',
      YOU_RECEIVE: 'VOUS RECEVEZ',
      SELL: 'Vendre',
      OPEN: 'Ouvrir',
      QUEUE: 'votre tour à travers',
      OPERATIONS: 'Opérations',
      CANCEL_ORDER: 'Annuler la commande',
      COMPLETED: 'Terminé',
      AMOUNT: 'Montant total',
      BUYBACK_INFO: "Vous souhaitez vendre instantanément sans faire la queue !? Plus d'informations peuvent être",
      BUYBACK_LINK: "trouvées ici",
    },
  },
};
