export const locale = {
  lang: 'id',
  data: {
    BREADCRUMB: {
      FORCE_SYSTEMS: 'Force systems',
      UNITEVERSE_DELTA: 'Uniteverse Delta',
      FORCECOIN: 'Forcecoin',
      MATRIX: 'Matrix',
      RECENT_ACTIVITY: 'Recent activity',
      LORE: 'Legends of Uniteverse',
      BACK: 'Back',
      OVERVIEW: 'Tambang',
      TIERS: 'Tingkat',
      PROCESSING: 'Gelombang',
    },
  },
};
