export const locale = {
  lang: 'en',
  data: {
    RECENT_OPERATIONS: {
      RECENT_TRANSACTIONS: 'Recent transactions',
      AMOUNT: 'Amount',
      NO_OPERATIONS: 'No operations',
      COPY_LINK: 'Copy link',
      TX_HASH: 'Tx hash',
    },
  },
};
