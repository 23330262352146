export const locale = {
  lang: 'fr',
  data: {
    RECENT_OPERATIONS: {
      RECENT_TRANSACTIONS: 'Transactions récentes',
      AMOUNT: 'Montant',
      NO_OPERATIONS: 'Aucune opération',
      COPY_LINK: 'Copier le lien',
      TX_HASH: 'Hachage de transaction',
    },
  },
};
