export const locale = {
  lang: 'fr',
  data: {
    SIDE_NAV_WALLET_BUYBACK: {
      BACK: "Retour",
      TITLE: "Vente anticipée de MFS.",
      IMPORTANTLY: "Important",
      INFO1: "Cet outil vous permet de vendre vos MFS hors tour, y compris les fonds dans le solde de vesting. Le coût des MFS lors de l'utilisation de cet outil est de 1 MFS: ${price} DAI.",
      INFO2: "* Lors de la vente de pièces à partir du solde de vesting, une redistribution aura lieu. Le montant restant des pièces dans le solde de vesting après la vente sera libéré uniformément sur la période de vesting restante.",
      INFO3: "Les MFS sont vendus dans cet ordre: <ul><li>d'abord les MFS libres du vesting;</li><li>puis les MFS du solde de vesting.</li></ul>Cet outil effectue des rachats de MFS par des limites. Si la limite actuelle est épuisée, vous devez attendre l'ouverture de la limite suivante.",
      YOU_GIVE: "VOUS DONNEZ",
      AVAILABLE: "DISPONIBLE",
      MAX: "Max",
      YOU_RECEIVE: "VOUS RECEVEZ",
      SELL: 'Vendre',
      OPERATIONS: "Opérations",
      COMPLETED: "Complété",
      AMOUNT: "Montant Total",
      NOT_AVAILABLE: "pas de DAI disponible",
      OFFER_YOUR_PRICE: "Offrez votre prix",
      BUYBACK_OFFER_REQUEST_YOUR_PRICE: 'Votre offre a été acceptée. Vous pouvez acheter des MFS au prix de l\'offre.',
    },
  },
};
