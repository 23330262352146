export const locale = {
  lang: 'ur',
  data: {
    CONFIRM_BUYBACK_OFFER_PRICE_MODAL: {
      TITLE: 'توجہ',
      INFO: 'آپ تصدیق کرتے ہیں کہ آپ فروخت کی مقررہ قیمت سے آگاہ ہیں: 1 MFS: ${price} DAI، اور آپ اس سے متفق ہیں۔ آپ یہ بھی تصدیق کرتے ہیں کہ آپ کو اس تبادلہ کی شرح اور پلیٹ فارم کے خلاف کوئی دعویٰ نہیں ہے۔',
      AGREEMENT: 'میں سب کچھ تصدیق کرتا ہوں',
      YES: 'جی ہاں',
      NO: 'نہیں',
    },
  },
};
