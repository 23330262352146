export const locale = {
  lang: 'ur',
  data: {
    SIDE_NAV_SWAP_HMFS_ON_MFS: {
      BACK: 'واپس',
      MAX: 'میکس',
      SWAP_HMFS_MFS:"Mfs کے لئے hmfs کا تبادلہ",
      SWAP:"تبادلہ",
      BALANCE: 'توازن:',
    },
  },
};
