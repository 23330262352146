export const locale = {
  lang: 'en',
  data: {
    CONNECT_WALLET: {
      HEADER_TITLE: 'Log in',
      BUTTON_BACK: 'Back',
      BODY_TITLE: 'Uniteverse Delta',
      BODY_DISCRIPTION:
        'In order to continue, you need to additionally log in <br> through reconnecting the wallet.',
      BODY_BUTTON_RECONECT: 'Connect wallet',
    },
  },
};
