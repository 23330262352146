export const locale = {
  lang: 'vi',
  data: {
    SIDE_NAV_WALLET_BUYBACK: {
      BACK: "Quay lại",
      TITLE: "Bán sớm MFS.",
      IMPORTANTLY: "Quan trọng",
      INFO1: "Công cụ này cho phép bạn bán MFS của mình ngoài thị trường, bao gồm cả quỹ trong số dư vesting. Giá của MFS khi sử dụng công cụ này là 1 MFS: ${price} DAI.",
      INFO2: "* Khi bán đồng xu từ số dư vesting, sẽ có sự phân bổ lại. Số lượng đồng xu còn lại trong số dư vesting sau khi bán sẽ được giải phóng đều đặn trong suốt thời gian vesting còn lại.",
      INFO3: "MFS được bán theo thứ tự này: <ul><li>Đầu tiên là MFS miễn phí từ vesting;</li><li>Sau đó là MFS từ số dư vesting.</li></ul>Công cụ này mua MFS theo giới hạn. Nếu giới hạn hiện tại đã hết thì bạn cần chờ đợi mở giới hạn tiếp theo.",
      YOU_GIVE: "Bạn cho",
      AVAILABLE: "Có sẵn",
      MAX: "Tối đa",
      YOU_RECEIVE: "Bạn nhận",
      SELL: 'Bán',
      OPERATIONS: "Hoạt động",
      COMPLETED: "Hoàn thành",
      AMOUNT: "Số lượng",
      NOT_AVAILABLE: "DAI không có sẵn",
      OFFER_YOUR_PRICE: "Đề xuất giá của bạn",
      BUYBACK_OFFER_REQUEST_YOUR_PRICE: 'Yêu cầu đề xuất của bạn đã được chấp nhận. Bạn có thể mua MFS với giá đề xuất.',
    },
  },
};
