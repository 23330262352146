export const locale = {
  lang: 'ar',
  data: {
    CONFIRM_GETHMFS_MODAL: {
      TITLE: 'انتباه',
      INFO: ' هل أنت متأكد أنك تريد أن تلتقط',
      INFO_1: ' بعد الاستلام ',
      INFO_2: ' سينتهي الحجز ولن تتمكن من الحصول على  أعلى',
      AGREEMENT: 'أؤكد كل شيء',
      YES: 'نعم',
      NO: 'لا',
      QUESTION:'هل أنت متأكد أنك تريد استلامه'
    },
  },
};
