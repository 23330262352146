export const locale = {
  lang: 'vi',
  data: {
    CONFIRM_GETHMFS_MODAL: {
      TITLE: 'Chú ý',
      INFO: ' Bạn có chắc chắn muốn nhận không',
      INFO_1: ' Sau khi nhận được ',
      INFO_2: ' việc nắm giữ sẽ kết thúc và bạn sẽ không thể nhận được  cao hơn.',
      AGREEMENT: 'Tôi xác nhận tất cả mọi thứ',
      YES: 'Có',
      NO: 'Không',
      QUESTION:'Bạn có chắc chắn muốn nhặt nó lên không'
    },
  },
};
