export const locale = {
  lang: 'ur',
  data: {
    SIDE_NAV_HOLD_MFS_WALLET: {
      BACK: 'واپس',
      HOLD_MFS:'MFS پکڑو',
      HOLD_HMFS:'hMFS پکڑو',
      HOLD: 'پکڑو',
      DAY: 'دن',
      GET: 'حاصل کریں',
      INNER_BALANCE: 'اندرونی توازن',
      HMFS_TO_MFS: 'MFS سے hMFS',
      TAKE_MFS:'MFS لے لو',
      TAKE_HMFS:'hMFS لے لو',
    },
  },
};
