export const locale = {
  lang: 'hi',
  data: {
    WALLET: {
      WALLET: 'वॉलेट',
      BALANCE: 'शेष',
      DISCONECT: 'डिस्कनेक्ट',
      CANCEL: 'रद्द करें',
      REPLENISH: 'पुन: भरें',
      SEND_TO_WALLET: 'वॉलेट में भेजें',
      EXTRA_WALLET: 'अतिरिक्त वॉलेट',
      CHECK_NAME: 'वॉलेट की स्वचालित भर्ती',
    },
  },
};
