export const locale = {
  lang: 'fr',
  data: {
    SIDE_NAV_HOLD_MFS_WALLET: {
      BACK: 'Dos',
      HOLD_MFS:'Maintenez MFS',
      HOLD_HMFS:'Maintenez hMFS',
      HOLD: 'PRISE',
      DAY: 'jour',
      GET: 'OBTENIR',
      INNER_BALANCE: 'Équilibre intérieur',
      HMFS_TO_MFS: 'hMFS vers MFS',
      TAKE_MFS:'PRENEZ MFS',
      TAKE_HMFS:'PRENEZ HMFS',
    },
  },
};
