export const locale = {
  lang: 'ru',
  data: {
    WALLET: {
      WALLET: 'Кошелек',
      BALANCE: 'Баланс',
      DISCONECT: 'Отключить',
      REPLENISH: 'Пополнить',
      SEND_TO_WALLET: 'Отправить на кошелек',
      EXTRA_WALLET: 'Дополнительный кошелек',
      CHECK_NAME: 'Автоматическое пополнение кошелька',
    },
  },
};
