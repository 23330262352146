export const locale = {
  lang: 'vi',
  data: {
    SIDE_NAV_HOLD_MFS: {
      BACK: 'Quay lại',
      MAX: 'Tối đa',
      HOLD: 'GIỮ',
      BALANCE: 'SỰ CÂN BẰNG:',
      GET:"Lấy",
      AVAILABLE:"Có sẵn",
      YOU_GET:"Bạn đang lấy"
    },
  },
};
