export const locale = {
  lang: 'ar',
  data: {
    SIDE_NAV_WALLET_ACTION: {
      BACK: 'العودة',
      TRANSFER: 'تحويل',
      INNER_BALANCE: 'الرصيد الداخلي',
      BALANCE: 'الرصيد',
      NUMBER: 'الرقم',
      FROM: 'من',
      TO: 'إلى',
      WALLET_BALANCE: 'رصيد المحفظة',
      MAX: 'الحد الأقصى',
      TRANSFER_NOW: 'نقل الآن',
    },
  },
};
