export const locale = {
  lang: 'hi',
  data: {
    SIDE_NAV_WALLET_ACTION: {
      BACK: 'वापस',
      TRANSFER: 'स्थानांतरण',
      INNER_BALANCE: 'आंतरिक शेष',
      BALANCE: 'शेष',
      NUMBER: 'संख्या',
      FROM: 'से',
      TO: 'को',
      WALLET_BALANCE: 'वॉलेट शेष',
      MAX: 'अधिकतम',
      TRANSFER_NOW: 'अब स्थानांतरण करें',
    },
  },
};
