export const locale = {
  lang: 'ur',
  data: {
    SIDE_NAV_HOLD_MFS: {
      BACK: 'واپس',
      MAX: 'میکس',
      HOLD: 'پکڑو',
      BALANCE: 'توازن:',
      GET:"وصول کریں",
      AVAILABLE:"دستیاب",
      YOU_GET:"آپ کو ملتا ہے"
    },
  },
};
