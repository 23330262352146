<div class="row h-100 m-0">
  <div
    class="col-12 d-flex align-items-center justify-content-between header-menu">
    <div class="header-title">
      {{ 'SIDE_NAV_NOTIFICATION.NOTIFICATION' | translate }}
    </div>
    <div class="d-flex align-items-center">
      <button class="btn button-grey">
        {{ 'SIDE_NAV_NOTIFICATION.MARK_AS_READ' | translate }}
      </button>
      <img
        class="button-close button-black"
        (click)="closeSlideNavMain(false)"
        src="/assets/icon/closeButton.svg"
        alt="" />
    </div>
  </div>

  <div class="col-12 d-flex height-container-services">
    <div class="h-100 not-found">
      {{ 'SIDE_NAV_NOTIFICATION.NEW_NOTIFICATION_NOT_FOUND' | translate }}
    </div>
    <div class="row m-0 justify-content-between align-content-start"></div>
  </div>
</div>
