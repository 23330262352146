<!-- Menu header -->
<div class="navbar-header">
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item mr-auto">
      <!-- App Branding -->
      <a class="navbar-brand" href="https://meta-force.space/">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" />
        </span>
        <h2 class="brand-text mb-0"></h2>
      </a>
    </li>

    <!-- Menu Toggler -->
    <li class="nav-item nav-toggle">
      <a
        class="nav-link modern-nav-toggle d-none d-xl-block pr-0"
        (click)="toggleSidebarCollapsible()">
        <!-- <img class="colaps-btn"
          [src]=" isCollapsed === false ? '/\assets/\images/\ColapsBtnClose.png' :'/\assets/\images/\ColapsBtnOpen.png' "
          alt=""> -->
        <img
          class="colaps-btn"
          [src]="'/assets/menu icon/ColapsBtnClose.png'"
          alt="" />
      </a>
      <a
        class="nav-link modern-nav-toggle d-block d-xl-none pr-0"
        (click)="toggleSidebar()">
        <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
      </a>
    </li>
  </ul>
</div>
<!--/ Menu header -->

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

<!-- Main menu -->
<div class="main-menu-content" [perfectScrollbar] (scroll)="onSidebarScroll()">
  <ul class="navigation navigation-main" layout="vertical" core-menu></ul>
  <div class="container-menu">
    <span
      class="text-white text-copied mr-1 badge badge-secondary"
      *ngIf="isCopy"
      >copied !</span
    >
    <a class="copy-link" (click)="copyReferalLink()">
      <div class="row m-0">
        <img class="icon-copy-link" src="/assets/menu icon/copyLink.png" />
        <div class="text-copy-button">
          {{ 'VERTICAL_MENU.COPY_LINK' | translate }}
        </div>
      </div>

      <img
        class="icon-copy-button"
        src="/assets/menu icon/copyIcon.png"
        alt="" />
    </a>
    <a class="force-id-button">
      <div class="row m-0">
        <div class="col-9 p-0 d-flex align-items-center">
          <img
            class="img-avatar"
            [src]="
              myInfo.users &&
              myInfo.users.avatar &&
              myInfo.users.avatar !== null
                ? myInfo.users.avatar
                : 'assets/images/User.png'
            "
            alt="" />
          <span class="id-value">
            {{
              myInfo.users && myInfo.users.name
                ? myInfo.users.name
                : ('VERTICAL_MENU.FORCE_ID' | translate)
            }}
          </span>
          <span
            class="text-white text-copied-forceId mr-1 badge badge-secondary"
            *ngIf="isCopyForceId"
            >copied !</span
          >
          <div class="container-icon-copy-grey" (click)="copyForceId()">
            <img
              class="icon-copy-button"
              src="/assets/menu icon/copyIcon-grey.png"
              alt="" />
          </div>
        </div>
        <div class="col-3 p-0 d-flex align-items-center container-forse-icon">
          <span class="icon">
            <!-- chevron_right -->
          </span>
        </div>
      </div>
    </a>
  </div>
</div>

<!--/ Main menu -->
