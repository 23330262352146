export const locale = {
  lang: 'ar',
  data: {
    CONNECT_WALLET: {
      HEADER_TITLE: 'تسجيل الدخول',
      BUTTON_BACK: 'العودة',
      BODY_TITLE: 'يونيفرس دلتا',
      BODY_DISCRIPTION:
        'للمتابعة، يجب عليك تسجيل الدخول إضافيًا <br> من خلال إعادة توصيل المحفظة.',
      BODY_BUTTON_RECONECT: 'توصيل المحفظة',
    },
  },
};
