export const locale = {
  lang: 'ru',
  data: {
    LAYOUT: {
      BACK: 'Назад',
      TITLE: 'Кошелек',
      WALLET_LIST: 'Список доступных кошельков',
      CLOSE: 'Закрыть',
    },
  },
};
