export const locale = {
  lang: 'ru',
  data: {
    SIDE_NAV_WALLET_ACTION: {
      BACK: 'Назад',
      TRANSFER: 'Перевести',
      INNER_BALANCE: 'Внутренний баланс',
      BALANCE: 'БАЛАНС',
      NUMBER: 'НОМЕР',
      FROM: 'ОТ',
      TO: 'К',
      WALLET_BALANCE: 'Баланс кошелька',
      MAX: 'Макс.',
      TRANSFER_NOW: 'Перевести сейчас',
    },
  },
};
