export const locale = {
  lang: 'en',
  data: {
    SIDE_NAV_WALLET_BUY: {
      BACK: 'Back',
      BUY_MFC: 'Buy MFS',
      IMPORTANTLY: 'Importantly',
      INFO: 'Now you can buy Forcecoin (MFS) without limitations. The minimum purchase amount is 20 MFS.',
      YOU_GIVE: 'YOU GIVE',
      AVAILABLE: 'AVAILABLE',
      MAX: 'Max',
      YOU_RECEIVE: 'YOU RECEIVE',
      BUY: 'Buy',
      WARNING: 'Attention',
      WARNING_INFO: 'Purchase under maintenance due to the preparation for the launch of the ForceDex exchange.',
    },
  },
};
