export const locale = {
  lang: 'zh',
  data: {
    MENU: {
      HOME: '首页',
      FORCE_SYSTEM: 'Force 系统',
      UNITEVERSE: 'Uniteverse',
      FORCECOIN: 'Forcecoin',
      MY_TEAM: '我的团队',
      ACADEMY: '学院',
      SETTINGS: '设置',
      HELP: '帮助',
      SOCIAL_META_FORCE: 'Social Meta Force',
      MESSENGER: 'Messenger',
      BADGE_MESSENGER: 'Beta',
    },
  },
};
