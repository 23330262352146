export const locale = {
  lang: 'vi',
  data: {
    SIDE_NAV_WALLET_ACTION: {
      BACK: 'Quay lại',
      TRANSFER: 'Chuyển',
      INNER_BALANCE: 'Số dư nội bộ',
      BALANCE: 'SỐ DƯ',
      NUMBER: 'SỐ LƯỢNG',
      FROM: 'TỪ',
      TO: 'ĐẾN',
      WALLET_BALANCE: 'Số dư ví tiền',
      MAX: 'Tối đa',
      TRANSFER_NOW: 'Chuyển ngay',
    },
  },
};
