export const locale = {
  lang: 'en',
  data: {
    SIDE_NAV_WALLET_ACTION: {
      BACK: 'Back',
      SELL_MFC: 'Sell MFS',
      IMPORTANTLY: 'Importantly',
      INFO: 'Here you can list your MFS for sale at a price of 2.77 DAI per 1 MFS. Your MFS will be sold to users who do not have MFS for activating tiers in Uniteverse. Applications are processed in the order of the queue. In case of partial sale, the application is sent to the end of the queue.',
      YOU_GIVE: 'YOU GIVE',
      AVAILABLE: 'AVAILABLE',
      MAX: 'Max',
      YOU_RECEIVE: 'YOU RECEIVE',
      SELL: 'Sell',
      OPEN: 'Open',
      QUEUE: 'your turn through',
      OPERATIONS: 'Operations',
      CANCEL_ORDER: 'Cancel order',
      COMPLETED: 'Completed',
      AMOUNT: 'Total amount',
      BUYBACK_INFO: "Wish to sell instantly without waiting in line!? More information can be",
      BUYBACK_LINK: "found here",
    },
  },
};
