export const locale = {
  lang: 'en',
  data: {
    BREADCRUMB: {
      FORCE_SYSTEMS: 'Force systems',
      UNITEVERSE_DELTA: 'Uniteverse Delta',
      FORCECOIN: 'Forcecoin',
      MATRIX: 'Matrix',
      RECENT_ACTIVITY: 'Recent transactions',
      LORE: 'Legends of Uniteverse',
      BACK: 'Back',
      OVERVIEW: 'Mine',
      TIERS: 'Tiers',
      PROCESSING: 'Waves',
    },
  },
};
