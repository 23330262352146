export const locale = {
  lang: 'id',
  data: {
    SIDE_NAV_HOLD_MFS: {
      BACK: 'Kembali',
      MAX: 'Maksimal',
      HOLD: 'Memegang',
      BALANCE: 'KESEIMBANGAN:',
      GET:"Terima",
      AVAILABLE:"Tersedia",
      YOU_GET:"Anda mendapatkan"
    },
  },
};
