export const locale = {
  lang: 'fr',
  data: {
    SIDE_NAV_WALLET_ACTION: {
      BACK: 'Back',
      TRANSFER: 'Transfer',
      INNER_BALANCE: 'Inner balance',
      BALANCE: 'BALANCE',
      NUMBER: 'NUMBER',
      FROM: 'FROM',
      TO: 'TO',
      WALLET_BALANCE: 'Wallet',
      MAX: 'Max',
      TRANSFER_NOW: 'Transfer now',
    },
  },
};
