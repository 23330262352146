export const locale = {
  lang: 'en',
  data: {
    CONFIRM_GETHMFS_MODAL: {
      TITLE: 'Attention',
      INFO: ' Are you sure you want to pick up',
      INFO_1: ' After receiving ',
      INFO_2: ' the holding will end and you will not be able to get higher ',
      AGREEMENT: 'I confirm everything',
      YES: 'Yes',
      NO: 'No',
      QUESTION:'Are you sure you want to pick it up'
    },
  },
};
