export const locale = {
  lang: 'hi',
  data: {
    MENU: {
      HOME: 'होम',
      FORCE_SYSTEM: 'फ़ोर्स सिस्टम',
      UNITEVERSE: 'यूनाइटवर्स',
      FORCECOIN: 'फ़ोर्सकॉइन',
      MY_TEAM: 'मेरी टीम',
      ACADEMY: 'एकेडमी',
      SETTINGS: 'सेटिंग्स',
      HELP: 'मदद',
      SOCIAL_META_FORCE: 'सोशल मेटा फोर्स',
      MESSENGER: 'Messenger',
      BADGE_MESSENGER: 'Beta',
    },
  },
};
