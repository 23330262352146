export const locale = {
  lang: 'ar',
  data: {
    SIDE_NAV_NOTIFICATION: {
      NOTIFICATION: 'Notification',
      MARK_AS_READ: 'Mark as read',
      NEW_NOTIFICATION_NOT_FOUND: 'New notification not found...',
    },
  },
};
