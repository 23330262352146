export const locale = {
  lang: 'id',
  data: {
    MENU: {
      HOME: 'Home',
      FORCE_SYSTEM: 'Force system',
      UNITEVERSE: 'Uniteverse',
      FORCECOIN: 'Forcecoin',
      MY_TEAM: 'My team',
      ACADEMY: 'Academy',
      SETTINGS: 'Settings',
      HELP: 'Help',
      SOCIAL_META_FORCE: 'Social Meta Force',
      MESSENGER: 'Messenger',
      BADGE_MESSENGER: 'Beta',
    },
  },
};
