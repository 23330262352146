export const locale = {
  lang: 'ar',
  data: {
    SIDE_NAV_WALLET_ACTION: {
      BACK: 'عودة',
      SELL_MFC: 'بيع MFS',
      IMPORTANTLY: 'الأهم',
      INFO: 'هنا يمكنك عرض MFS الخاص بك للبيع بسعر 2.77 DAI لكل 1 MFS. سيتم بيع MFS الخاص بك للمستخدمين الذين لا يملكون MFS لتفعيل الطبقات في Uniteverse. تتم معالجة الطلبات وفقًا لترتيب الوصول. في حالة البيع الجزئي، يتم إرسال الطلب إلى نهاية الطابور.',
      YOU_GIVE: 'أنت تعطي',
      AVAILABLE: 'متاح',
      MAX: 'الحد الأقصى',
      YOU_RECEIVE: 'أنت تتلقى',
      SELL: 'بيع',
      OPEN: 'افتح',
      QUEUE: 'دورك من خلال',
      OPERATIONS: 'العمليات',
      CANCEL_ORDER: 'إلغاء الطلب',
      COMPLETED: 'مكتمل',
      AMOUNT: 'المبلغ الإجمالي',
      BUYBACK_INFO: "هل ترغب في البيع الفوري بدون انتظار في الطابور؟ يمكن الحصول على مزيد من المعلومات",
      BUYBACK_LINK: "هنا",
    },
  },
};
