export const locale = {
  lang: 'ar',
  data: {
    MENU: {
      HOME: 'الصفحة الرئيسية',
      FORCE_SYSTEM: 'نظام القوة',
      UNITEVERSE: 'اليونيفيرس',
      FORCECOIN: 'فورسكوين',
      MY_TEAM: 'فريقي',
      ACADEMY: 'الأكاديمية',
      SETTINGS: 'الإعدادات',
      HELP: 'المساعدة',
      SOCIAL_META_FORCE: 'ميتا القوة الاجتماعية',
      MESSENGER: 'Messenger',
      BADGE_MESSENGER: 'Beta',
    },
  },
};
