export const locale = {
  lang: 'id',
  data: {
    SIDE_NAV_HOLD_MFS_WALLET: {
      BACK: 'Kembali',
      HOLD_MFS:'Tahan MFS',
      HOLD_HMFS:'Tahan hMFS',
      HOLD: 'MEMEGANG',
      DAY: 'hari',
      GET: 'MENDAPATKAN',
      INNER_BALANCE: 'Keseimbangan Batin',
      HMFS_TO_MFS: 'hMFS ke MFS',
      TAKE_MFS:'AMBIL MFS',
      TAKE_HMFS:'AMBIL hMFS',
    },
  },
};
