export const locale = {
  lang: 'id',
  data: {
    CONFIRM_GETHMFS_MODAL: {
      TITLE: 'Perhatian',
      INFO: ' Apakah Anda yakin ingin mengambilnya',
      INFO_1: ' Setelah menerima ',
      INFO_2: ' holding akan berakhir dan Anda tidak akan bisa mendapatkan  yang lebih tinggi.',
      AGREEMENT: 'Saya mengonfirmasi semuanya',
      YES: 'Ya',
      NO: 'Tidak',
      QUESTION:'Apakah Anda yakin ingin mengambilnya'
    },
  },
};
