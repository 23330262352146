export const locale = {
  lang: 'zh',
  data: {
    LAYOUT: {
      BACK: '返回',
      TITLE: '钱包',
      WALLET_LIST: '可用钱包列表',
      CLOSE: '关闭',
    },
  },
};
