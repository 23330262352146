export const locale = {
  lang: 'hi',
  data: {
    EXPECTATION_MODAL: {
      STEP: 'स्टेप ',
      OUT_OF: ' 2 में से',
      CONFIRM_ACTION: 'क्रिया की पुष्टि करें',
      CONFIRM_ACTION_BODY:
        'स्मार्ट कॉन्ट्रैक्ट में इस ऑपरेशन का उद्देश्य आपकी अनुमति प्राप्त करना है वर्तमान कॉन्ट्रैक्ट के भीतर धन प्रबंधित करने के लिए। आप उन धन की सीमा सेट करते हैं जिनकी आप स्मार्ट कॉन्ट्रैक्ट को प्रबंधन के लिए स्थानांतरित करने के लिए तैयार हैं। कृपया दिए गए फ़ील्ड में उन राशि को दर्ज करें जिस पर आप स्मार्ट कॉन्ट्रैक्ट को प्रबंधन करने की अनुमति देना चाहते हैं। ध्यान दें कि यह राशि वर्तमान क्रिया के मूल्य से अधिक या उसके बराबर होनी चाहिए। राशि दर्ज करने के बाद, "कन्फ़र्म" पर क्लिक करें और क्रिया के साथ आगे बढ़ने के लिए। हमारी सेवा का उपयोग करने के लिए धन्यवाद!',
      PLEASE_CONFIRM_YOUR_TRANSACTION_WALLET:
        'कृपया अपने लेन-देन की पुष्टि अपने वॉलेट में करें।',
      FINAL_STEP: 'अंतिम स्टेप',
      PLEASE_WAIT: 'कृपया प्रतीक्षा करें।',
      IT_TAKES_UP_30_SEC: 'इसमें से 30 सेकंड लग सकते हैं',
      PLEASE_WAIT_BODY:
        'ऑपरेशन स्मार्ट कॉन्ट्रैक्ट में क्रियान्वित हो रहा है। कृपया हमारे ट्रांजैक्शन को प्रोसेस करते समय विंडो को बंद न करें और पृष्ठ को रिफ़्रेश न करें। ऑपरेशन का क्रियान्वयन स्मार्ट कॉन्ट्रैक्ट की ओर से होता है और सामान्यतः 1 से 30 सेकंड के बीच होता है, लेकिन यदि नेटवर्क भरा हुआ है तो यह अधिक समय लग सकता है। हम आपकी सब्र की कदर करते हैं और ऑपरेशन के पूरा होने की सूचना देंगे।',
    },
  },
};
