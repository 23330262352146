export const locale = {
  lang: 'vi',
  data: {
    BREADCRUMB: {
      FORCE_SYSTEMS: 'Hệ thống Lực lượng',
      UNITEVERSE_DELTA: 'Uniteverse Delta',
      FORCECOIN: 'Đồng Force',
      MATRIX: 'Ma trận',
      RECENT_ACTIVITY: 'Hoạt động gần đây',
      LORE: 'Legends of Uniteverse',
      BACK: 'Back',
      OVERVIEW: 'Mỏ',
      TIERS: 'Cấp độ',
      PROCESSING: 'Sóng',
    },
  },
};
