export const locale = {
  lang: 'ru',
  data: {
    CONFIRM_GETHMFS_MODAL: {
      TITLE: 'Внимание',
      INFO: ' Вы уверены, что хотите забрать',
      INFO_1: ' После получения ',
      INFO_2: ' холдинг закончится и Вы не сможете получить более высокие ',
      AGREEMENT: 'Подтверждаю всё',
      YES: 'Да',
      NO: 'Нет',
      QUESTION:'Вы уверены что хотите забрать'
    },
  },
};
