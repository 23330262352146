export const locale = {
  lang: 'hi',
  data: {
    RECENT_OPERATIONS: {
      RECENT_TRANSACTIONS: 'हाल की लेन-देन',
      AMOUNT: 'राशि',
      NO_OPERATIONS: 'कोई ऑपरेशन नहीं',
      COPY_LINK: 'लिंक कॉपी करें',
      TX_HASH: 'ट्रांजेक्शन हैश',
    },
  },
};
