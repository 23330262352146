export const locale = {
  lang: 'ru',
  data: {
    RECENT_OPERATIONS: {
      RECENT_TRANSACTIONS: 'Последние транзакции',
      AMOUNT: 'Сумма',
      NO_OPERATIONS: 'Операций нет',
      COPY_LINK: 'Копировать ссылку',
      TX_HASH: 'Хеш транзакции',
    },
  },
};
