export const locale = {
  lang: 'zh',
  data: {
    SIDE_NAV_WALLET_ACTION: {
      BACK: '返回',
      TRANSFER: '转账',
      INNER_BALANCE: '内部余额',
      BALANCE: '余额',
      NUMBER: '数量',
      FROM: '从',
      TO: '到',
      WALLET_BALANCE: '钱包余额',
      MAX: '最大',
      TRANSFER_NOW: '立即转账',
    },
  },
};
