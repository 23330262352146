export const locale = {
  lang: 'vi',
  data: {
    CONNECT_WALLET: {
      HEADER_TITLE: 'Đăng nhập',
      BUTTON_BACK: 'Quay lại',
      BODY_TITLE: 'Uniteverse Delta',
      BODY_DISCRIPTION:
        'Để tiếp tục, bạn cần đăng nhập thêm <br> bằng cách kết nối lại ví tiền.',
      BODY_BUTTON_RECONECT: 'Kết nối ví tiền',
    },
  },
};
