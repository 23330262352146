export const locale = {
  lang: 'hi',
  data: {
    SIDE_NAV_SWAP_HMFS_ON_MFS: {
      BACK: 'वापस',
      MAX: 'मैक्स',
      SWAP_HMFS_MFS:"के लिए MFS का आदान-प्रदान",
      SWAP:"एक्सचेंज",
      BALANCE: 'शेष राशि:',
    },
  },
};
