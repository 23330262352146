export const locale = {
  lang: 'hi',
  data: {
    CONNECT_WALLET: {
      HEADER_TITLE: 'लॉग इन करें',
      BUTTON_BACK: 'वापस',
      BODY_TITLE: 'यूनाइटवर्स डेल्टा',
      BODY_DISCRIPTION:
        'जारी रखने के लिए, आपको अतिरिक्त रूप से लॉग इन करने की आवश्यकता है <br> वॉलेट को फिर से कनेक्ट करके।',
      BODY_BUTTON_RECONECT: 'वॉलेट कनेक्ट करें',
    },
  },
};
