export const locale = {
  lang: 'ru',
  data: {
    SIDE_NAV_NOTIFICATION: {
      NOTIFICATION: 'Уведомление',
      MARK_AS_READ: 'Отметить как прочитанное',
      NEW_NOTIFICATION_NOT_FOUND: 'Новых уведомлений не найдено...',
    },
  },
};
