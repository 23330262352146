export const locale = {
  lang: 'zh',
  data: {
    CONNECT_WALLET: {
      HEADER_TITLE: '登录',
      BUTTON_BACK: '返回',
      BODY_TITLE: 'Uniteverse Delta',
      BODY_DISCRIPTION: '为了继续，您需要通过重新连接钱包进行额外的登录。<br>',
      BODY_BUTTON_RECONECT: '连接钱包',
    },
  },
};
