export const locale = {
  lang: 'zh',
  data: {
    CONFIRM_BUYBACK_OFFER_PRICE_MODAL: {
      TITLE: '注意',
      INFO: '您确认您已经知道设定的售价：1 MFS: ${price} DAI，并且您同意它。您还确认您没有任何关于此汇率的要求，并且对于平台。',
      AGREEMENT: '我确认所有事情',
      YES: '是',
      NO: '否',
    },
  },
};
