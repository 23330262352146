export const locale = {
  lang: 'fr',
  data: {
    CONNECT_WALLET: {
      HEADER_TITLE: 'Se connecter',
      BUTTON_BACK: 'Retour',
      BODY_TITLE: 'Uniteverse Delta',
      BODY_DESCRIPTION:
        'Pour continuer, vous devez vous reconnecter au portefeuille.',
      BODY_BUTTON_RECONNECT: 'Se connecter au portefeuille',
    },
  },
};
