export const locale = {
  lang: 'zh',
  data: {
    RECENT_OPERATIONS: {
      RECENT_TRANSACTIONS: '最近交易',
      AMOUNT: '金额',
      NO_OPERATIONS: '无操作',
      COPY_LINK: '复制链接',
      TX_HASH: '交易哈希',
    },
  },
};
