export const locale = {
  lang: 'ar',
  data: {
    SIDE_NAV_SWAP_HMFS_ON_MFS: {
      BACK: 'خلف',
      MAX: 'الأعلى',
      SWAP_HMFS_MFS:"تبادل hMFS إلى MFS",
      SWAP:"تبادل",
      BALANCE: 'توازن:',
    },
  },
};
