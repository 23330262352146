export const locale = {
  lang: 'vi',
  data: {
    MENU: {
      HOME: 'Trang chủ',
      FORCE_SYSTEM: 'Hệ thống Lực lượng',
      UNITEVERSE: 'Uniteverse',
      FORCECOIN: 'Forcecoin',
      MY_TEAM: 'Đội của tôi',
      ACADEMY: 'Học viện',
      SETTINGS: 'Cài đặt',
      HELP: 'Trợ giúp',
      SOCIAL_META_FORCE: 'Xã hội Meta Force',
      MESSENGER: 'Messenger',
      BADGE_MESSENGER: 'Beta',
    },
  },
};
