export const locale = {
  lang: 'ru',
  data: {
    SIDE_NAV_HOLD_MFS_WALLET: {
      BACK: 'Назад',
      HOLD_MFS:'Холд MFS',
      HOLD_HMFS:'Холд hMFS',
      HOLD: 'ХОЛД',
      DAY: 'день',
      GET: 'ПОЛУЧИТЬ',
      INNER_BALANCE: 'Внутренний баланс',
      HMFS_TO_MFS: 'hMFS в MFS',
      TAKE_MFS:'ЗАБРАТЬ MFS',
      TAKE_HMFS:'ЗАБРАТЬ hMFS',
    },
  },
};
