export const locale = {
  lang: 'ru',
  data: {
    CONNECT_WALLET: {
      HEADER_TITLE: 'Вход',
      BUTTON_BACK: 'Назад',
      BODY_TITLE: 'Uniteverse Delta',
      BODY_DISCRIPTION:
        'Для продолжения необходимо дополнительно войти <br> через переподключение кошелька.',
      BODY_BUTTON_RECONECT: 'Подключить кошелек',
    },
  },
};
