export const locale = {
  lang: 'vi',
  data: {
    WALLET: {
      WALLET: 'Ví tiền',
      BALANCE: 'Số dư nội bộ',
      DISCONECT: 'Ngắt kết nối',
      CANCEL: 'Hủy',
      REPLENISH: 'Nạp thêm',
      SEND_TO_WALLET: 'Gửi vào ví',
      CHECK_NAME: 'Tự động nạp tiền vào ví',
      EXTRA_WALLET: 'Ví phụ',
    },
  },
};
