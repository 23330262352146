export const locale = {
  lang: 'hi',
  data: {
    SIDE_NAV_WALLET_BUYBACK: {
      BACK: "वापस",
      TITLE: "MFS की प्रारंभिक बिक्री.",
      IMPORTANTLY: "महत्वपूर्ण रूप से",
      INFO1: "यह टूल आपको आपके MFS को बाजार के बाहर बेचने की अनुमति देता है, जिसमें वेस्टिंग बैलेंस में मौजूद फंड्स भी शामिल हैं। इस टूल का उपयोग करते समय MFS की कीमत 1 MFS: ${price} DAI है।",
      INFO2: "* वेस्टिंग बैलेंस से सिक्कों को बेचने पर, पुनर्वितरण होगा। बिक्री के बाद वेस्टिंग बैलेंस में शेष सिक्कों की राशि शेष वेस्टिंग अवधि के दौरान समान रूप से जारी की जाएगी।",
      INFO3: "MFS इस क्रम में बिकते हैं: <ul><li>सबसे पहले वेस्टिंग से मुक्त MFS;</li><li>फिर वेस्टिंग बैलेंस से MFS बेचे जाते हैं।</li></ul>यह टूल MFS को खरीदता है लिमिट्स के साथ। यदि वर्तमान सीमा समाप्त हो गई है तो आपको अगली सीमा का इंतजार करना होगा।",
      YOU_GIVE: "आप देते हैं",
      AVAILABLE: "उपलब्ध",
      MAX: "अधिकतम",
      YOU_RECEIVE: "आप प्राप्त करेंगे",
      SELL: 'बेचें',
      OPERATIONS: "संचालन",
      COMPLETED: "पूर्ण",
      AMOUNT: "कुल राशि",
      NOT_AVAILABLE: "DAI उपलब्ध नहीं है",
      OFFER_YOUR_PRICE: "अपनी कीमत पेश करें",
      BUYBACK_OFFER_REQUEST_YOUR_PRICE: 'आपकी ऑफर अनुरोधित हो गई है। आप ऑफर कीमत पर MFS खरीद सकते हैं।',
    },
  },
};
