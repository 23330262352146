export const locale = {
  lang: 'ar',
  data: {
    LAYOUT: {
      BACK: 'رجوع',
      TITLE: 'المحفظة',
      WALLET_LIST: 'قائمة المحافظ المتاحة',
      CLOSE: 'إغلاق',
    },
  },
};
