export const locale = {
  lang: 'id',
  data: {
    SIDE_NAV_SWAP_HMFS_ON_MFS: {
      BACK: 'Kembali',
      MAX: 'Maksimal',
      SWAP_HMFS_MFS:"HMS ke MFS exchange",
      SWAP:"Pertukaran",
      BALANCE: 'KESEIMBANGAN:',
    },
  },
};
