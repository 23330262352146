export const locale = {
  lang: 'vi',
  data: {
    LAYOUT: {
      BACK: 'Quay lại',
      TITLE: 'Ví tiền',
      WALLET_LIST: 'Danh sách ví tiền khả dụng',
      CLOSE: 'Đóng',
    },
  },
};
