export const locale = {
  lang: 'fr',
  data: {
    LAYOUT: {
      BACK: 'Retour',
      TITLE: 'Portefeuille',
      WALLET_LIST: 'La liste des portefeuilles disponibles',
      CLOSE: 'Fermer',
    },
  },
};
