<ng-container *ngIf="!item.hidden">
  <!-- collapsible title -->
  <a
    class="d-flex align-items-center"
    [ngClass]="item.classes"
    *ngIf="!item.url"
    (click)="toggleOpen($event)">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- itemContent template -->
  <ng-template #itemContent>
    <span [data-feather]="item.icon" *ngIf="item.icon"></span>
    <img
      class="img-size-for-menu-item"
      *ngIf="item.img"
      [src]="item.img"
      alt="icon" />
    <span class="menu-title text-truncate" [translate]="item.translate">{{
      item.title
    }}</span>
    <span
      class="badge ml-auto mr-1"
      *ngIf="item.badge"
      [translate]="item.badge.translate"
      [ngClass]="item.badge.classes">
      {{ item.badge.title }}
    </span>
  </ng-template>

  <!-- sub-menu item/collapsible -->
  <ul
    *ngIf="item.isCollapsibleSocial"
    class="menu-content row justify-content-between">
    <ng-container *ngFor="let item of item.children">
      <li class="containet-social">
        <a class="p-0" href="item.url">
          <img
            class="img-size-for-menu-item m-0"
            *ngIf="item.img"
            [src]="item.img"
            alt="icon" />
        </a>
      </li>
    </ng-container>
  </ul>
  <ul *ngIf="!item.isCollapsibleSocial" class="menu-content">
    <ng-container *ngFor="let item of item.children">
      <!-- item -->
      <li
        core-menu-vertical-item
        [item]="item"
        *ngIf="item.type == 'item'"
        [ngClass]="{ disabled: item.disabled === true }"
        [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
        [routerLinkActiveOptions]="{ exact: item.exactMatch || false }">
        <span
          [routerLink]="item.openInNewTab ? [] : [item.url]"
          class="d-none"></span>
      </li>
      <!-- collapsible -->
      <li
        core-menu-vertical-collapsible
        *ngIf="item.type == 'collapsible'"
        [item]="item"
        class="nav-item has-sub"></li>
    </ng-container>
  </ul>
</ng-container>
