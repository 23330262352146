export const locale = {
  lang: 'hi',
  data: {
    LAYOUT: {
      BACK: 'वापस',
      TITLE: 'वॉलेट',
      WALLET_LIST: 'उपलब्ध वॉलेट्स की सूची',
      CLOSE: 'बंद करें',
    },
  },
};
