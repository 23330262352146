export const locale = {
  lang: 'ru',
  data: {
    MENU: {
      HOME: 'Главная',
      FORCE_SYSTEM: 'Force Система',
      UNITEVERSE: 'Uniteverse',
      FORCECOIN: 'Forcecoin',
      MY_TEAM: 'Моя команда',
      ACADEMY: 'Академия',
      SETTINGS: 'Настройки',
      HELP: 'Помощь',
      SOCIAL_META_FORCE: 'Social Meta Force',
      MESSENGER: 'Messenger',
      BADGE_MESSENGER: 'Beta',
    },
  },
};
